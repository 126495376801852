.engagement-analytical-page {
  > .card {
    > .card-header {
      > .input-group {
        width: initial;
      }
    }
  }

  > .modal {
    > .modal-dialog {
      > .modal-content {
        > .modal-body {
          > div {
            > li {
              list-style-type: none;
              > div {
                > div {
                  > .icon {
                    margin: 5%;
                    width: 60px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(var(--primary-color-rgb), 0.05);
                    transition: all 0.2s ease;
                    // transform: translate(-50%, calc(-50% - 10px));
                    border-radius: 50%;
                    // position: absolute;
                    z-index: 2;
                    // left: calc(var(--spacing-padding) + 18px);
                    // top: 50%;
                
                    > svg {
                      max-width: 50%;
                      max-height: 50%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.borderBottom {
  padding: 2%;
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.borderBottom:last-child {
  border-bottom: 0px solid;
}
