.badge {
  font-weight: 400;
}

.badge-primaryLabel {
  background-color: var(--primary-color) !important;
}

.badge-secondaryLabel {
  background-color: var(--success-color) !important;
}

.badge-tag {
  background-color: #eee;
  border-radius: 30px;
  color: var(--text-color);
  font-size: 1rem;
}
