.dashboard-page {
  padding-bottom: 0 !important;

  > .participants-count {
    border-radius: var(--border-radius) var(--border-radius) 0 0;

    > .card-body {
      > ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > li {
          list-style: none;
          position: relative;

          &:not(:last-child)::after {
            width: 1px;
            height: 22px;
            content: '';
            display: block;
            background-color: var(--gray-color);
            transform: translateY(-50%);
            position: absolute;
            right: 0;
            top: 50%;
          }

          &:not(:last-child) {
            padding-right: 16px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
