.form-select {
  line-height: 1rem;
  border-radius: 5px;
}

.form-control {
  line-height: 1rem;
  padding: 7px 16px;
}

.form-control:is(input[type="text"], input[tyoe="email"], input[type="password"]) {
  padding: 8px 16px;
}

.input-group-text.blank-input {
  background-color: transparent;
  border-color: transparent;
}

.select-control {
  display: block;
  width: 100%;
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
}

.input-group > .select-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.lined-textarea {
  font-size: 14px;
  line-height: 16px;
  background-image: url(../../images/textarea-lines.png);
  background-attachment: local;
  background-repeat: no-repeat;
  padding-left: 35px;
  padding-top: 10px;
}

select.form-control {
  appearance: auto !important;
}
