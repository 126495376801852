.nav-item .nav-link.active {
  background-color: #fff;
  color: var(--primary-color);
}

.nav-link {
  color: var(--primary-color);
  margin-top: 5px;
  margin-bottom: 5px;
  padding: .7rem 1rem;
}

.nav-link:hover {
  background-color: #fff;
  color: var(--primary-color);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #fff;
  color: var(--primary-color);
}

.navbar-no-pad {
  padding-top: 0px;
  padding-bottom: 0px;
}

.navbar-color {
  background-color: #dee2e6;
  padding-top: 0px;
  padding-bottom: 0px;
}
