.students-register-degrees-page {
  .degrees {
    > .degree-item {
      > .class-list {
        > .class-item {
          > .input-group {
            > .btn {
              padding: 0 6px;
            }
          }
        }
      }

      > .add-new-class {
        > .input-group {
          > .btn {
            padding: 0 12px;
          }
        }
      }
    }
  }
}
