.tests-and-resolutions-page {
  .questions-selection {
    > .questions {
      display: flex;
      flex-wrap: wrap;
      margin-left: -2px;
      margin-right: -2px;
      margin-bottom: 15px;

      > .question {
        width: calc(6.66% - 4px);
        background-color: #eee;
        margin: 0 2px 5px;
        border: 0;
        text-align: center;
        border-bottom: 3px solid var(--success-color);
        transition: background-color 0.2s ease;
        font-size: 1rem;
        padding: 6px 0;

        &.is-annulled {
          border-color: var(--warning-color);
        }

        &.is-correct {
          border-color: var(--success-color);
        }

        &.is-wrong {
          border-color: var(--danger-color);
        }

        > small {
          display: block;
          text-transform: uppercase;
        }

        &:hover,
        &.active {
          background-color: var(--primary-color);
          color: #fff;
        }
      }
    }
  }

  .alternatives {
    > .alternative {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      > .subtitle {
        width: 100%;
        padding-top: 10px;
        text-align: center;
        border-top: 2px solid #ddd;
        color: var(--primary-color);
      }

      &.is-correct > .subtitle {
        color: var(--success-color);
      }
    }
  }

  .enunciation {
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 100%;
  }
}
