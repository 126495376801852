:root {
  font-size: 14px;

  --primary-color: #060181;
  --primary-color-table-ascent: #e6e6f1;
  --primary-color-rgb: 5, 1, 126;
  --primary-color-shadow: 0 0 0 0.25rem rgb(var(--primary-color-rgb) / 25%);
  --secondary-color: #f74a4b;
  --secondary-color-rgb: 247, 74, 75;
  --disabled-color: #a0a0a0;
  --silver-color: #aaa;
  --gray-color: #d8d8d8;
  --border-color: #e5e5f2;
  --text-color: #343434;
  --success-color: #70cb90;
  --danger-color: #fc819d;
  --warning-color: #faba4a;
  --dark-color: #d4d4d4;
  --light-color: #f7f7fb;

  --navy-blue-color: #060181;
  --seance-color: #59196f;
  --royal-heath-color: #832765;
  --night-shadz-color: #a8325d;
  --carnation-color: #f74a4b;

  --spacing-margin: 32px;
  --spacing-padding: 32px;
  --border-radius: 5px;
  --box-shadow: 0 2px 3px 0 rgba(var(--primary-color-rgb), .1);
  --box-shadow-hover: 0 2px 10px 0 rgba(var(--primary-color-rgb), 0.1);

  --bs-success-rgb: 112, 203, 144;
}

body {
  font-family: 'Evo', sans-serif;
  background-color: #f7f7fb;
  font-size: 1rem;
  color: var(--text-color);
}

strong {
  font-weight: 500;
}

.text-primary {
  color: var(--primary-color) !important;
}

.text-secondary {
  color: var(--secondary-color) !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.outline {
  border: 1px solid #eee;
  border-radius: var(--border-radius);
}

.boxed-container {
  background-color: #e5e5f2;
}

.bordered {
  border: 1px solid var(--border-color);
}

.border-rounded {
  border-radius: var(--border-radius);
}

.border-none {
  border-color: transparent;
}

.react-tags__suggestions {
  z-index: 9999999;
}

.stroke-primary-color {
  stroke: var(--primary-color) !important;
}

.fill-primary-color {
  fill: var(--primary-color) !important;
}

.stroke-secondary-color {
  stroke: var(--secondary-color) !important;
}

.fill-secondary-color {
  fill: var(--secondary-color) !important;
}

.cursor-pointer {
  cursor: pointer;
}

.nav-tabs {
  background-color: #f0f0f0;

  > .nav-item {
    > .nav-link {
      margin-top: 0;
      border-radius: 0;
      border: 0;
    }
  }
}
