.table:not(.table-default) {
  thead {
    tr {
      th {
        font-weight: 400;
        padding: 12px 10px;
        vertical-align: middle;
        background-color: #fff !important;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }
    }
  }

  tbody tr:nth-of-type(odd),
  tbody tr:nth-of-type(odd) td {
    background-color: #fff;
    color: var(--text-color);
  }

  tbody tr:nth-of-type(even),
  tbody tr:nth-of-type(even) td {
    background-color: var(--primary-color-table-ascent) !important;
    color: var(--text-color);
  }

  tbody {
    tr {
      td {
        background: transparent;
        box-shadow: none !important;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      &.tr-primaryLabel {
        background-color: rgba(var(--primary-color-rgb), 0.1) !important;
      }

      &.tr-secondaryLabel {
        background-color: rgba(var(--primary-color-rgb), 0.2) !important;
      }
    }
  }

  &:not(.table-bordered) {
    > thead > tr > th,
    > tbody > tr > td {
      border: 0;
    }
  }
}

.table th,
.table td {
  padding: 12px 10px;
  text-align: left;
}

.table th.frozen,
.table td.frozen {
  position: sticky;
  left: 0;
  top: 0;
}

.hit {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.hit.hit-correct {
  background-image: url(../../svg/confirmed.svg);
}

.hit.hit-wrong {
  background-image: url(../../svg/error.svg);
}

.table>:not(:first-child) {
  border-top: 1px solid var(--dark-color) !important;
}

.accordion-item {
  border-color: var(--dark-color) !important;
}
