.advanced-menu {
  > .competition-comparator,
  > .metas {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
    border: 1px solid transparent;
    border-radius: var(--border-radius);
    background-color: transparent;
    margin-bottom: 0;
    cursor: pointer;
    opacity: 0;

    > .title {
      text-align: left;
      color: var(--primary-color);
      font-weight: 500;
    }

    &.metas-color-completed {
      background-color: var(--primary-color) ;
      border-color: var(--primary-color);

      > .title {
        color: #FFF;
      }
    }

    &:hover {
      background-color: var(--primary-color);

      > .title {
        color: #FFF;
      }
    }
  }

  &.is-active > .competition-comparator,
  &.is-active > .metas {
    height: 90px;
    border-color: var(--primary-color);
    margin-bottom: 20px;
    opacity: 1;
  }
}
