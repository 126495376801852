h1 {
  font-weight: 400;
  font-size: 2.714rem;
}

h2 {
  font-weight: 600;
  font-size: 2.8rem;
}

h3 {
  font-weight: 600;
  font-size: 1.8rem;
}

.text-upper {
  text-transform: uppercase;
}

.text-nowrap {
  white-space: nowrap;
}
