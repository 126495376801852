.downloads-page {
  .btn-primary {
    margin-top: 2%;
  }

  > div 
  > .card {
    > .card-header {
      > .input-group {
        width: initial;
      }
    }
    > .card-body {
      > .card-download {
        background-color: rgba(var(--primary-color-rgb), 0.056);
        padding: 1%;
      }
    }
  }
}
