.assessments-page {
  .assessments-selection {
    > .card-header {
      > .input-group {
        width: auto;

        > .form-select {
          max-width: 100px;
          margin-left: 0 !important;
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
        }
      }
    }

    > .card-body {
      > .advanced-menu{
        > .nav {
          display: flex;
          flex-wrap: nowrap;

          > .btn {
            margin: 0 4px;
            line-height: 1rem;
            padding: 8px 4px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      > .nav {
        display: flex;
        flex-wrap: nowrap;

        > .btn {
          margin: 0 4px;
          line-height: 1rem;
          padding: 8px 4px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      > .tabs > .tab > .list-group > .list-group-item {
        padding: 0;
        border: none;

        > .btn {
          width: 100%;
          display: flex;
          justify-content: space-between;
          border: 1px solid var(--border-color);
          border-left: 5px solid var(--primary-color);
          align-items: center;
          padding: 12px 20px;
          text-align: left;
          margin-bottom: 10px;

          > .assessment-details {
            > .title {
              margin-bottom: 4px;
            }

            > .dates,
            > .status {
              > svg {
                margin-right: 2px;
                vertical-align: middle;
                position: relative;
                top: -2px;
              }
            }
          }

          &:hover {
            border-color: var(--secondary-color);
          }
        }

        &:last-child > .btn {
          margin-bottom: 0;
        }
      }
    }
  }
}
