.loading-page-container {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 10px 15px;
  background: rgba(34, 34, 34, 0.4);
  color: #fff;
  .dots-container{
    position: absolute;
    left: calc(50% - 37.5px);
    top: calc(50% - 37.5px);
    width: 75px;
    height: 75px;

    span {
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 4px;
      background-color: #fff;
      width: 25px;
      height: 25px;
      animation: animate 2s linear infinite;

      &:nth-child(1) {
        background-color: var(--primary-color);
        animation-delay: 0s;
      }
      &:nth-child(2) {
        background-color: var(--primary-color);
        animation-delay: -0.667s;
      }
      &:nth-child(3) {
        background-color: var(--secondary-color);
        animation-delay: -1.33s;
      }

      @keyframes animate {
        0% {
          top: 0;
          left: 0;
        }
        12.5% {
          top: 0;
          left: 50%;
        }
        25% {
          top: 0;
          left: 50%;
        }
        37.5% {
          top: 50%;
          left: 50%;
        }
        50% {
          top: 50%;
          left: 50%;
        }
        62.5% {
          top: 50%;
          left: 0;
        }
        75% {
          top: 50%;
          left: 0;
        }
        87.5% {
          top: 0;
          left: 0;
        }
        100% {
          top: 0;
          left: 0;
        }
      }
    }
  }
}
