.btn {
  white-space: nowrap;
  padding: 4px var(--spacing-padding);
  border-radius: var(--border-radius);
}

.btn[class*="-primary"] {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn[class*="-primary"]:hover {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn[class*="-primary"]:disabled,
.btn[class*="-primary"].disabled {
  border-color: var(--disabled-color);
  background-color: var(--disabled-color);
  box-shadow: 0px 2px 4px rgba(100,100,100,0.5);
}

.btn[class*="-outline-primary"] {
  color: var(--primary-color);
  background-color: transparent;
  border-color: var(--primary-color);
}

.btn[class*="-outline-primary"]:hover {
  color: var(--secondary-color);
  border-color: var(--secondary-color);
  background-color: transparent;
}

.btn[class*="-outline"]:disabled,
.btn[class*="-outline"].disabled {
  color: var(--disabled-color);
  border-color: var(--disabled-color);
  background-color: transparent;
}

.btn:disabled,
.btn.disabled {
  box-shadow: none !important;
}

.btn-link {
  text-decoration: none;
  padding: 0;
}

button.card {
  transition: box-shadow 0.2s ease;
}

button.card:hover {
  box-shadow: var(--box-shadow-hover);
}

.btn-rounded {
  border-radius: 60px;
}

.btn-option {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: none !important;
  outline: none;

  > span {
    text-align: left;
  }
}

.btn.btn-loading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .spinner-border {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 8px;
  }
}

.btn-link {
  color: var(--primary-color);
}

.btn-link:hover {
  color: var(--secondary-color);
}

.btn-light {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}
