.teachers-and-products-page {
  .table {
    thead > tr > th {
      font-weight: 500;
      border-bottom: 0;
    }

    > tbody {
      border-top: 0 !important;
      background-color: var(--light-color);

      > tr:last-child > td {
        border-bottom: 0;
      }
    }
  }

  .accordion {
    strong {
      font-weight: 500;
    }

    .teacher-name {
      display: block;
      max-width: 140px;
      margin-bottom: 5px;

      > span {
        width: 100%;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        background-color: var(--primary-color-table-ascent);
        border-radius: 36px;
        font-size: 0.875rem;
        padding: 4px 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .accordion-collapse > table > tbody > tr:first-child > td {
    border-top-width: 1px;
    border-color: var(--border-color);
  }
}
