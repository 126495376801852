.assessment-models-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  > .card {
    width: 100%;
    max-width: 592px;

    > .card-body {
      > ul {
        margin: 0;
        padding: 0;

        > li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: var(--spacing-padding) 16px;
          border-bottom: 1px solid var(--border-color);
          list-style: none;

          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
