.area-component {
  > .card {
    position: relative;
    overflow: hidden;

    > .card-body {
      padding: calc(var(--spacing-padding) / 2) 16px;

      > .row {
        > div {
          > .icon {
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(var(--primary-color-rgb), 0.05);
            transition: all 0.2s ease;
            transform: translate(-50%, calc(-50% - 10px));
            border-radius: 56px;
            position: absolute;
            z-index: 2;
            left: calc(var(--spacing-padding) + 18px);
            top: 50%;

            > svg {
              max-width: 32px;
              max-height: 32px;
            }
          }

          &:first-child {
            display: flex;
            justify-content: center;
          }
        }

        > .area-content {
          min-height: 75px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          transition: opacity 0.5s ease-out;
          opacity: 1;

          > .averange {
            line-height: 1.714rem;
            font-size: 1.714rem;

            > .editable-value {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              margin-bottom: 5px;

              > .form-control {
                width: 100px;
              }

              > .btn {
                padding: 0;

                > svg {
                  fill: var(--primary-color);
                }
              }
            }
          }

          > .details {
            margin-top: 6px;

            > .row {
              > div {
                > .number {
                  font-size: 0.857rem;
                }

                > span {
                  color: var(--silver-color);
                  font-size: 0.857rem;
                }
              }
            }
          }

          > .btn {
            color: var(--primary-color);
            font-size: 0.857rem;
            padding: 0;
          }
        }
      }

      .row {
        align-items: center;
        justify-content: space-between;
      }
    }

    > .mouse-over {
      width: 0;
      height: 100%;
      position: absolute;
      transition: all 0.2s ease;
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      overflow: hidden;
      opacity: 0;
      z-index: 1;
      bottom: 0;
      left: 0;

      > button {
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        box-shadow: none;
        color: #fff;
        border: 0;

        > span {
          white-space: nowrap;
          position: relative;
          top: 50px;
        }
      }
    }
  }

  &:not(.has-animation) > .card > .mouse-over {
    display: none;
  }

  &.has-animation > .card:hover {
    > .card-body > .row {
      > div > .icon {
        left: 50%;
        background-color: rgba(255, 255, 255, 0.3);
      }

      > .area-content {
        opacity: 0;
      }
    }

    > .mouse-over {
      width: 100%;
      opacity: 1;
    }
  }
}
