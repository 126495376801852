.loading-container {
  width: 264px;
  height: 192px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-out;
  border-radius: 5px;
  position: absolute;
  visibility: hidden;
  z-index: 9999;
  opacity: 0;
  left: 50%;
  top: 50%;

  > span {
    margin-top: 16px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 0;
}

.loading-container > .spinner,
.loading > .spinner {
  width: 75px;
  height: 75px;
  content: '';
  display: block;
  -webkit-animation: spin 1.6s ease-in-out infinite;
  -moz-animation: spin 1.6s ease-in-out infinite;
  animation: spin 1.6s ease-in-out infinite;

  > svg {
    width: 100%;
    height: 100%;
    stroke: var(--primary-color);
  }
}

.loading-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.1s ease-out;
  visibility: hidden;
  z-index: 9998;
  opacity: 0;
  left: 0;
  top: 0;
}

.has-loading.is-loading .loading-container,
.has-loading.is-loading .loading-overlay {
  opacity: 1;
  visibility: visible;
}

.has-loading.loading-light {
  .loading-container {
    box-shadow: none;
    background-color: transparent;
  }

  .loading-overlay {
    background-color: #fff;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
